import React from "react"
import ReactDOM from "react-dom/client"
import "./App.css"
import Pages from "./components/pages/Pages"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
      <Pages />
  </React.StrictMode>
)
export const domain = "https://api.panda-xoum.ru"
